<template>
    <van-form class="container" @submit="userLogin">
        <div class="content">
            <van-image
                class="logo"
                :src="require(`@/assets/${type}/logo.png`)"
                fit="contain"
            />
            <div class="prompt prompt-header">
                <span class="prompt-text"> 免安装费 </span>
                <div class="prompt-line"></div>
                <span class="prompt-text"> 最快次日送达 </span>
            </div>
            <van-field
                v-model="form.mobile"
                type="number"
                placeholder="请输入手机号"
                class="form-input"
            />
            <van-field
                v-model="form.validateCode"
                type="number"
                placeholder="输入验证码"
                maxlength="6"
                center
                class="form-input"
            >
                <template #button>
                    <van-button
                        round
                        type="primary"
                        :disabled="clickActive"
                        size="mini"
                        class="flex"
                        @click="sendCode"
                    >
                        {{ sendbtn }}
                    </van-button>
                </template>
            </van-field>
            <van-button
                round
                block
                class="login"
                :loading="loading"
                :disabled="loading"
                native-type="submit"
            >
                登录
            </van-button>

            <div class="prompt prompt-footer">
                <span class="prompt-text">
                    请优先使用下单时预留的手机号进行预约
                </span>
            </div>
        </div>

        <div class="footer">
            <div class="other-title justify-center">
                <span>登录注册代表同意</span>
                <router-link to="/protocol" class="other-link">
                    《用户管理协议》
                </router-link>
                <span>和</span>
                <router-link to="/privacy" class="other-link">
                    《用户隐私政策》
                </router-link>
            </div>
        </div>

        <!-- <van-dialog v-model:show="show" title="提示">
            <div class="dialog-content">
                尊敬的用户：VOC服务管理系统当前正在维护，维护时间8月30日11：00-17：00，请您在维护结束后进行预约，给您带来不便敬请谅解，如您安装需求比较紧急，依然可以致电
                <a style="color: #1989fa" @click="callMobile('4006-0888-77')">
                    4006 0888 77
                </a>
                服务热线进行预约安装。
            </div>
        </van-dialog> -->
    </van-form>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import settings from "@/settings";
import { verifyEmpty, go, callMobile } from "@/utils";
import { openIdKey } from "@/utils/data";
import { login } from "@/api";
import { shortMessageLogin } from "@/api/shortMessage";

export default {
    name: "Login",
    setup() {
        let form = reactive({
            mobile: "", // 手机号
            validateCode: "", // 验证码
        });
        let state = reactive({
            loading: false, // 登录中（防止重复点击）
            clickActive: false, // 验证码是否发送
            sendbtn: "点击获取", // 验证码按钮文字
            // show: true,
            type: settings.type,
        });

        // 记录地址，登录后直接返回
        let query = useRoute().query;
        let query_path =
            query.path && decodeURIComponent(query.path) !== "/login"
                ? decodeURIComponent(query.path)
                : "/";
        delete query.path;
        let query_other = query;

        // 发送验证码
        async function sendCode() {
            await verifyEmpty(form.mobile, "手机号码");
            state.clickActive = true;
            state.sendbtn = "发送中...";
            try {
                await shortMessageLogin(form.mobile);
                Toast.success("发送成功");
                let second = 60;
                state.sendbtn = second + "S后重试";
                let t = setInterval(() => {
                    state.sendbtn = --second + "S后重试";
                    if (second <= 0) {
                        clearInterval(t);
                        state.clickActive = false;
                        state.sendbtn = "点击获取";
                    }
                }, 1000);
            } catch (err) {
                state.clickActive = false;
                state.sendbtn = "点击获取";
            }
        }
        // 登录
        async function userLogin() {
            let data = {
                loginType: "MEMBER_MOBILE_CODE",
                mobile: form.mobile,
                validateCode: form.validateCode,
                openIdKey: openIdKey.value,
            };
            await verifyEmpty(form.mobile, "手机号码");
            await verifyEmpty(form.validateCode, "验证码");
            state.loading = true;
            try {
                await login(data);
                go(query_path, query_other);
            } finally {
                state.loading = false;
            }
        }

        return {
            form,
            ...toRefs(state),

            sendCode,
            userLogin,
            callMobile,
        };
    },
};
</script>

<style lang="less" scoped>
@import url("vant/lib/style/var.less");
@import url("@/styles/mixin.less");
.container {
    .flex(space-around, center, column);
    min-height: 100vh;
    .flex {
        .flex();
    }
    .content {
        .flex(center, center, column);
        flex-shrink: 0;
        flex: 1;
        width: calc(100% - 60px);
        max-width: 600px;

        .logo {
            width: 100px;
            height: 100px;
            display: block;
        }
        .form-input {
            .flex();
            box-shadow: inset 0 -1px 0 0 rgba(220, 220, 220, 0.5);
            width: 100%;
            margin-bottom: 15px;
            height: 40px;
            border-radius: 20px;
            box-sizing: border-box;
            padding: 0 20px;
            background-color: #eee;
            white-space: nowrap;
            input {
                font-size: 14px;
                width: 100%;
                border: none;
                padding: 0;
                height: 100%;
                background-color: transparent;
                &::placeholder {
                    color: #ccc;
                }
            }
        }
        .login {
            .btn-color();
            font-size: 14px;
            margin-top: 20px;
        }
        .prompt {
            .flex(space-between, center);
            width: 100%;
            padding: 0 20px;
            font-size: 12px;
            margin-bottom: 10px;
            &.prompt-header {
                .flex(center, center);
                margin-top: 15px;
                margin-bottom: 20px;
                font-size: 14px;
            }
            &.prompt-footer {
                margin-top: 15px;
                .flex(center, center);
            }
            .prompt-text {
                color: rgba(0, 0, 0, 0.4);
            }
            .prompt-line {
                height: 12px;
                margin: 0 20px;
                background-color: #979797;
                width: 1px;
            }
        }
    }

    .footer {
        .flex(center, center, column);
        .divider {
            width: 60vw;
            border-color: #ccc;
            color: #ccc;
            margin: 10px 0;
        }
        .other-title {
            .flex(center);
            margin: 20px auto 30px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.4);
            .other-link {
                color: @blue;
                text-decoration: underline;
            }
        }
    }

    .dialog-content {
        padding: 10px 20px 20px;
        font-size: 14px;
    }
}
</style>
