import request from '@/utils/request'
import { openIdKey } from '@/utils/data'

// 发送忘记密码短信（取消）
export function shortMessagePassword(mobile) {
    return request({
        url: '/app/shortMessage/forgetPassword/' + mobile,
        method: 'post',
    })
}

// 发送登录短信
export function shortMessageLogin(mobile) {
    return request({
        url: '/app/shortMessage/login/' + mobile,
        method: 'post',
        params: { openIdKey: openIdKey.value }
    })
}

// 发送注册短信（取消）
export function shortMessageRegister(mobile) {
    return request({
        url: '/app/shortMessage/register/' + mobile,
        method: 'post',
    })
}
